import React from "react";

class Locations extends React.Component {
  render() {
    return (
      <div className="p-5">
        <h1>Location</h1>
        <p>2050 Old Hickory Tree Rd., St Cloud, FL 34772</p>
      </div>
    );
  }
}

export default Locations;
