import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import jennifer from "./Staff/DrVan.jpg";
import catherine from "./Staff/DrBall.jpg";
import phu from "./Staff/drphuvan.jpg";

class MeetOurTeam extends React.Component {
  render() {
    return (
      <div>
        <Container>
          <h1>Meet Our Dentists</h1>
          <Row className="mx-auto py-5">
            <Col xs={12} sm={12} md={12} lg={6} xl={6}>
              <img
                className="p-4"
                src={jennifer}
                alt="Dr. Jennifer Van, DMD"
                style={{
                  backgroundImage:
                    "linear-gradient(315deg, #ffffff 0%, #d7e1ec 74%)",
                  borderRadius: "0",
                  width: "100%",
                  height: "auto",
                }}
              ></img>
            </Col>
            <Col xs={12} sm={12} md={12} lg={6} xl={6}>
              <div className="px-1 py-4">
                <h3 className="pb-4">Dr. Jennifer T. Van, DMD</h3>
                <p>
                  Dr. Van attended Florida International University and then
                  Nova Southeastern University College of Dental Medicine where
                  she earned her Doctorate of Dental Medicine.
                </p>
                <p>
                  Dr. Van loves every aspect of dentistry, but what she loves
                  most is making a nervous patient feel so comfortable that they
                  can fall asleep in their chair during procedures. Dr. Van's
                  life long goal is to be able to use her talents and skills to
                  help those less fortunate. Her charities include rescuing and
                  reforming human sex trafficked victims, feeding the homeless,
                  and sending supplies to underserved communities. One day she
                  hopes to go to the disadvantaged countries and give free
                  dental treatment to their citizens. Dr. Van is married with 5
                  wonderful children. In her spare time, she loves to sing and
                  cook for her small (lol) family.
                </p>
              </div>
            </Col>
          </Row>
          <Row className="mx-auto pb-5">
            <Col xs={12} sm={12} md={12} lg={6} xl={6}>
              <img
                className="p-4"
                src={catherine}
                alt="Dr. Catherine Ball, DMD"
                style={{
                  backgroundImage:
                    "linear-gradient(315deg, #ffffff 0%, #d7e1ec 74%)",
                  borderRadius: "0",
                  width: "100%",
                  height: "auto",
                }}
              ></img>
            </Col>
            <Col xs={12} sm={12} md={12} lg={6} xl={6}>
              <div className="px-1 py-4">
                <h3 className="pb-4">Dr. Catherine S. Ball, DMD</h3>
                <p>
                  Dr. Ball is a graduate of the University of Alabama at
                  Birmingham School of Dentistry and holds a Bachelor of Science
                  from the University of Georgia.
                </p>
                <p>
                  She is a native of central Florida and has enjoyed practicing
                  dentistry in St. Cloud for over 10 years. Dr. Ball has always
                  had a love for science and helping people, and found both in
                  her career as a dentist. She treats all patients with kindness
                  and loves to help educate them about their dental health so
                  they can better understand and take care of their smiles. In
                  her time away from the office, she enjoys traveling near and
                  far with her husband and two children. She also stays busy
                  cheering for the Georgia Bulldogs, cooking, and enjoying the
                  local theme parks.
                </p>
              </div>
            </Col>
          </Row>
          <Row className="mx-auto pb-4">
            <Col xs={12} sm={12} md={12} lg={6} xl={6}>
              <img
                className="p-4"
                src={phu}
                alt="Dr. Phu Van, DMD"
                style={{
                  backgroundImage:
                    "linear-gradient(315deg, #ffffff 0%, #d7e1ec 74%)",
                  borderRadius: "0",
                  width: "100%",
                  height: "auto",
                }}
              ></img>
            </Col>
            <Col xs={12} sm={12} md={12} lg={6} xl={6}>
              <div className="px-1 py-4">
                <h3 className="pb-4">Dr. Phu Van, DMD</h3>
                <p>
                  Dr. Phu Van is a skilled and compassionate dentist, born in
                  Colorado but grew up mostly in Florida. He attended high
                  school and university in South Florida, where his family is
                  from. After graduating Magna Cum Laude from Florida Atlantic
                  University, he pursued his Doctorate of Dental Medicine at the
                  University of Florida (Go Gators!).
                </p>
                <p>
                  While attending UF College of Dental Medicine, he dedicated a
                  majority of his time and effort to care for his patients in
                  clinics and excel his dental skills. In addition to his
                  academic and clinical excellence, he was also nominated by the
                  Omicron Kappa Upsilon dental fraternity for the Ethics Award.
                  Dr. Van firmly believes in striving to provide the best care
                  possible for his patients by advancing his knowledge and
                  skills with continuing education. Therefore, he enjoys all
                  aspects of dentistry including root canals therapy, crown and
                  bridge restorations, extractions, partial and complete
                  dentures - as well as the intricate challenges of extensive
                  treatments such as cosmetic dentistry, implant surgery and
                  full-mouth rehabilitation. Furthermore, many patients know Dr.
                  Van to be a gentle dentist that offers a pain-free experience
                  and prioritizes patient comfort and care. When Dr. Van is not
                  caring for patients, he enjoys what Florida has to offer.
                  Whether it is deep sea fishing, swimming in the crystal clear
                  waters of one of many Florida beaches, or camping with friends
                  and family, he thoroughly enjoys spending time outside. Dr.
                  Van loves traveling, trying out all of the cuisines from
                  different parts of the world and learning about other
                  cultures.
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default MeetOurTeam;
