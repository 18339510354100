import React from "react";
import "./App.css";
import { Container, Row, Col } from "react-bootstrap";
import insurance from "./Icons/insurance.png";

class Insurance extends React.Component {
  render() {
    return (
      <div className="pt-4 pb-5" style={{ textAlign: "left" }}>
        <Container>
          <Row className="m-auto">
            <Col
              xs={9}
              sm={5}
              md={4}
              lg={3}
              xl={3}
              className="mx-auto text-center pb-3"
            >
              <img class="w-100 p-5" src={insurance} />
              <a
                href="https://patientportal-cs5.carestack.com/?dn=stcloud/#/online-appointments"
                className="button appt"
              >
                BOOK AN APPT
              </a>
            </Col>
            <Col xs={12} sm={9} md={9} lg={9} xl={9} className="py-5">
              <h1 className="mb-4" style={{ fontWeight: "300" }}>
                Insurance
              </h1>
              <p className="pb-3">
                We accept many dental insurance plans and will file claims on
                your behalf, saving you the time and hassle. Our knowledgeable
                benefit coordinators can help you maximize your dental benefits
                and minimize your out-of-pocket cost. We will tell you upfront
                what your insurance plan will pay for and offer options for
                taking care of any remaining balance.
              </p>
              <p className="pb-3">
                We accept and honor most dental insurance plans including the
                ones listed below. Please call our office for more details:
                (407) 556-3969
              </p>
              <Row>
                <Col>
                  <ul>
                    <li>Aetna</li>
                    <li>Ameritas</li>
                    <li>Anthem</li>
                    <li>Assurant</li>
                    <li>Blue Cross Blue Shield</li>
                  </ul>
                </Col>
                <Col>
                  <ul>
                    <li>Carington</li>
                    <li>Cigna</li>
                    <li>Delta Dental</li>
                    <li>Dentemax</li>
                    <li>GEHA</li>
                  </ul>
                </Col>
                <Col>
                  <ul>
                    <li>Humana</li>
                    <li>MetLife</li>
                    <li>Principal</li>
                    <li>Sunlife</li>
                    <li>United Concordia</li>
                    <li>United Health Care</li>
                  </ul>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default Insurance;
