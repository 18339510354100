import React from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { withRouter } from "react-router-dom";
import "./App.css";
// Appointment date: can we restrict it to where you can't chose days where the office is closed?
// Appointment time: min/max does not seem to be working
class AppointmentForm extends React.Component {
  render() {
    return (
      <div style={{ height: "200px" }}>
        <h2 className="text-center py-5 my-0" style={{ fontWeight: "300" }}>
          <a href="https://patientportal-cs5.carestack.com/?dn=stcloud/#/online-appointments">
            Request an appointment online
          </a>
        </h2>
      </div>
    );
  }
}

export default withRouter(AppointmentForm);
