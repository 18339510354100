import React from "react";
import Carousel from "./Carousel";
import OfficeHours from "./OfficeHours";
import MissionStatement from "./MissionStatement";
//import "./styles.css";

class Main extends React.Component {
  render() {
    return (
      <div>
        <Carousel />
        <OfficeHours />
        <MissionStatement />
      </div>
    );
  }
}

export default Main;
