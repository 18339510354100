import React from "react";
import Map from "./Map";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import OfficeHours from "./OfficeHours";
import "./App.css";
// Change map image to Google Map API

class Contact extends React.Component {
  render() {
    return (
      <div className="m-auto">
        <div className="m-auto">
          <Map></Map>
        </div>
        <div
          className="my-4 m-auto w-75"
          style={{
            justifyContent: "left",
            display: "flex",
            flexWrap: "wrap",
            whiteSpace: "nowrap",
            minWidth: "0",
          }}
        >
          <h5 className="mx-3">CONTACT</h5>
          <p className="mx-3">
            Phone:
            <a
              className="mx-2 email"
              href="tel:+14075563969"
              style={{
                fontSize: "16px",
              }}
            >
              <b>(407) 556-3969</b>
            </a>
          </p>
          <p className="mx-3">
            Fax:
            <a
              className="mx-2 email"
              href="fax:+13216822009"
              style={{
                fontSize: "16px",
              }}
            >
              <b>(321) 682-2009</b>
            </a>
          </p>
          <p className="mx-3">
            Email:
            <a
              className="mx-2 email"
              href="mailto:stcloudsmiles@gmail.com"
              style={{
                fontSize: "16px",
              }}
            >
              <b>stcloudsmiles@gmail.com</b>
            </a>
          </p>
        </div>
        <div
          className="pb-5 m-auto w-75"
          style={{
            justifyContent: "left",
            display: "flex",
            flexWrap: "wrap",
            whiteSpace: "nowrap",
            minWidth: "0",
          }}
        >
          <h5 className="mx-3">BUSINESS HOURS</h5>
          <p
            className="mx-3"
            style={{
              fontSize: "16px",
            }}
          >
            Mon <b>8:00 AM - 5:00 PM</b>
          </p>
          <p
            className="mx-3"
            style={{
              fontSize: "16px",
            }}
          >
            Tues <b>By Appointment Only</b>
          </p>
          <p
            className="mx-3"
            style={{
              fontSize: "16px",
            }}
          >
            Wed <b>8:00 AM - 5:00 PM</b>
          </p>
          <p
            className="mx-3"
            style={{
              fontSize: "16px",
            }}
          >
            Thur <b>8:00 AM - 5:00 PM</b>
          </p>
          <p
            className="mx-3"
            style={{
              fontSize: "16px",
            }}
          >
            Fri <b>8:00 AM - 5:00 PM</b>
          </p>
          <p
            className="mx-3"
            style={{
              fontSize: "16px",
            }}
          >
            Sat <b>By Appointment Only</b>
          </p>
          <p
            className="mx-3"
            style={{
              fontSize: "16px",
            }}
          >
            Sun <b>Closed</b>
          </p>
        </div>
      </div>
    );
  }
}

export default Contact;
