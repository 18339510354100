import React from "react";
import { Row, Col } from "react-bootstrap";
import fbicon from "./Icons/image.png";
import gicon from "./Icons/image2.png";
import igicon from "./Icons/image3.png";
import "./App.css";

class Footer extends React.Component {
  render() {
    return (
      <div
        className="p-4"
        style={{
          justifyContent: "left",
          color: "#C0C0C0",
          backgroundColor: "#333",
          height: "auto",
          justifyContent: "left",
          flexWrap: "wrap",
          whiteSpace: "nowrap",
          minWidth: "0",
        }}
      >
        <Row>
          <Col xs={12} s={3} m={3} l={3} xl={3}>
            <hr></hr>
            <p style={{ color: "white", fontWeight: 350 }}>CONTACT</p>
            <hr></hr>
            <p style={{ fontSize: 16 }}>St. Cloud Smiles</p>
            <p style={{ fontSize: 16 }}>
              2050 Old Hickory Tree Rd
              <br />
              St. Cloud, FL 36772
            </p>
            <p style={{ fontSize: 16 }}>
              <a class="footer-link" href="tel://+14075563969">
                (407) 556-3969
              </a>
            </p>
            <p style={{ fontSize: 16 }}>
              <a href="Appointment" class="footer-link">
                Request an Appointment
              </a>
            </p>
          </Col>
          <Col>
            <hr></hr>
            <p style={{ color: "white", fontWeight: 350 }}>HOURS</p>
            <hr></hr>
            <Row>
              <Col xs={3} s={3} m={3} l={3} xl={3}>
                <p style={{ fontSize: 16 }}>MON</p>
              </Col>
              <Col xs={9} s={9} m={9} l={9} xl={9}>
                <p style={{ fontSize: 16 }}>8:00 AM - 5:00 PM</p>
              </Col>
            </Row>
            <Row>
              <Col xs={3} s={3} m={3} l={3} xl={3}>
                <p style={{ fontSize: 16 }}>TUE</p>
              </Col>
              <Col xs={9} s={9} m={9} l={9} xl={9}>
                <p style={{ fontSize: 16 }}>By Appointment Only</p>
              </Col>
            </Row>
            <Row>
              <Col xs={3} s={3} m={3} l={3} xl={3}>
                <p style={{ fontSize: 16 }}>WED</p>
              </Col>
              <Col xs={9} s={9} m={9} l={9} xl={9}>
                <p style={{ fontSize: 16 }}>8:00 AM - 5:00 PM</p>
              </Col>
            </Row>
            <Row>
              <Col xs={3} s={3} m={3} l={3} xl={3}>
                <p style={{ fontSize: 16 }}>THU</p>
              </Col>
              <Col xs={9} s={9} m={9} l={9} xl={9}>
                <p style={{ fontSize: 16 }}>8:00 AM - 5:00 PM</p>
              </Col>
            </Row>
            <Row>
              <Col xs={3} s={3} m={3} l={3} xl={3}>
                <p style={{ fontSize: 16 }}>FRI</p>
              </Col>
              <Col xs={9} s={9} m={9} l={9} xl={9}>
                <p style={{ fontSize: 16 }}>8:00 AM - 5:00 PM</p>
              </Col>
            </Row>
            <Row>
              <Col xs={3} s={3} m={3} l={3} xl={3}>
                <p style={{ fontSize: 16 }}>SAT</p>
              </Col>
              <Col xs={9} s={9} m={9} l={9} xl={9}>
                <p style={{ fontSize: 16 }}>By Appointment Only</p>
              </Col>
            </Row>
            <Row>
              <Col xs={3} s={3} m={3} l={3} xl={3}>
                <p style={{ fontSize: 16 }}>SUN</p>
              </Col>
              <Col xs={9} s={9} m={9} l={9} xl={9}>
                <p style={{ fontSize: 16 }}>CLOSED</p>
              </Col>
            </Row>
          </Col>
          <Col xs={12} s={3} m={3} l={3} xl={3}>
            <hr></hr>
            <p style={{ color: "white", fontWeight: 350 }}>INFORMATION</p>
            <hr></hr>
            <p style={{ fontSize: 16 }}>Notice of Privacy Practices</p>
            <p style={{ fontSize: 16 }}>Privacy Policy</p>
            <p style={{ fontSize: 16 }}>Notice of Teledentistry Practices</p>
            <p style={{ fontSize: 16 }}>Notice of Non-Discrimination</p>
          </Col>
          <Col xs={12} s={3} m={3} l={3} xl={3}>
            <hr></hr>
            <p style={{ color: "white", fontWeight: 350 }}>CONNECT</p>
            <hr></hr>
            <Row>
              <a
                href="https://www.instagram.com/stcloudsmilesfl/"
                target="blank"
                class="footer-link"
              >
                <img
                  className="my-2"
                  src={igicon}
                  style={{ width: "25px", height: "25px", marginRight: "14px" }}
                ></img>
                Follow us on Instagram
              </a>
            </Row>
            <Row>
              <a
                href="https://www.facebook.com/SaintCloudSmiles/"
                target="blank"
                class="footer-link"
              >
                <img
                  className="my-2"
                  src={fbicon}
                  style={{ width: "25px", height: "25px", marginRight: "14px" }}
                ></img>
                Like us on Facebook
              </a>
            </Row>
            <Row>
              <a
                href="https://g.page/stcloudsmilesfl?share"
                target="blank"
                class="footer-link"
              >
                <img
                  className="my-2"
                  src={gicon}
                  style={{ width: "25px", height: "25px", marginRight: "14px" }}
                ></img>
                Find us on Google
              </a>
            </Row>
          </Col>
        </Row>
        {/* <Row>
          <div
            className="mt-5 mb-4"
            style={{ color: "#909090", fontSize: 12, whiteSpace: "wrap" }}
          >
            <p style={{ fontSize: 16 }}>
              &copy;Copyright 2022 St. Cloud Smiles General and Cosmetic
              Dentistry
            </p>
            <p style={{ fontSize: 16 }}>
              <u>FLORIDA CONSUMER PRIVACY ACT</u>&nbsp; Learn more about new
              data privacy rights for Florida Residents.
            </p>
          </div>
        </Row> */}
      </div>
    );
  }
}

export default Footer;
