import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

class OfficeHours extends React.Component {
  render() {
    return (
      <div>
        <div
          className="pt-5"
          style={{
            justifyContent: "center",
            display: "flex",
            flexWrap: "wrap",
            whiteSpace: "nowrap",
            minWidth: "0",
          }}
        >
          <h5 className="mx-5">BUSINESS HOURS</h5>
          <p
            className="mx-2"
            style={{
              fontSize: "16px",
            }}
          >
            Mon <b>8:00 AM - 5:00 PM</b>
          </p>
          <p
            className="mx-2"
            style={{
              fontSize: "16px",
            }}
          >
            Tues <b>By Appointment Only</b>
          </p>
          <p
            className="mx-2"
            style={{
              fontSize: "16px",
            }}
          >
            Wed <b>8:00 AM - 5:00 PM</b>
          </p>
          <p
            className="mx-2"
            style={{
              fontSize: "16px",
            }}
          >
            Thur <b>8:00 AM - 5:00 PM</b>
          </p>
          <p
            className="mx-2"
            style={{
              fontSize: "16px",
            }}
          >
            Fri <b>8:00 AM - 5:00 PM</b>
          </p>
          <p
            className="mx-2"
            style={{
              fontSize: "16px",
            }}
          >
            Sat <b>By Appointment Only</b>
          </p>
          <p
            className="mx-2"
            style={{
              fontSize: "16px",
            }}
          >
            Sun <b>Closed</b>
          </p>
        </div>
        <div className="px-3 py-4">
          <p style={{ fontSize: 16 }}>
            Please call to schedule an appointment or book online! We are OPEN
            for ALL dental care procedures and emergency needs. Protecting the
            health and safety of our patients, families, and team members remains
            our number one priority.
          </p>
        </div>
        {/* <div>
            <h5 style={{ display: "flex", textAlign: "right" }}>
              BUSINESS HOURS
            </h5>
          <<div>
          <div xs={10}>
            <p style={{ fontSize: "18px" }}>
              Mon <b>7:00 AM - 4:00 PM</b>&nbsp; Tues <b>Closed</b>&nbsp; Wed{" "}
              <b>7:00 AM - 4:00 PM</b>&nbsp; Thurs <b>7:00 AM - 4:00 PM</b>
              &nbsp; Fri <b>7:00 AM - 4:00 PM</b>&nbsp; Sat <b>Closed</b>&nbsp;
              Sun <b>Closed</b>&nbsp;
            </p>
          <<div>
        </Row>
        <Row>
          <p>
            Please call to schedule an appointment or book online! We are OPEN
            for ALL dental care procedures and emergency needs. Protecting the
            health and safety of our patients, families, and team members emains
            our number one priority.
    </p> */}
      </div>
    );
  }
}

export default OfficeHours;
