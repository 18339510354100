import "./App.css";
import Main from "./Main";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import NavComponent from "./Nav";
import Footer from "./Footer";
import GeneralDentistry from "./GeneralDentistry";
import CosmeticDentistry from "./CosmeticDentistry";
import RootCanals from "./RootCanals";
import Crowns from "./Crowns";
import Emergencies from "./Emergencies";
import MeetOurTeam from "./MeetOurTeam";
import Staff from "./Staff";
import Forms from "./Forms";
import Insurance from "./Insurance";
import Contact from "./Contact";
import Payment from "./Payment";
import AppointmentForm from "./AppointmentForm";
import AppointmentRequest from "./AppointmentRequest";
import About from "./About";
import Locations from "./Locations";
import React, { Component } from "react";

class App extends Component {
  constructor() {
    super();
    this.state = {
      width: window.innerWidth,
    };
  }

  componentWillMount() {
    window.addEventListener("resize", this.handleWindowSizeChange);
  }

  // make sure to remove the listener
  // when the component is not mounted anymore
  componentWillUnmount() {
    window.removeEventListener("resize", this.handleWindowSizeChange);
  }

  handleWindowSizeChange = () => {
    this.setState({ width: window.innerWidth });
  };

  render() {
    return (
      <div className="App">
        <div
          className="body-main pb-2"
          style={{ minHeight: "0", position: "sticky", top: 0, zIndex: 99 }}
        >
          <NavComponent></NavComponent>
        </div>
        <div className="body-main">
          <Router>
            <Switch>
              <Route path="/GeneralDentistry">
                <GeneralDentistry></GeneralDentistry>
              </Route>
              <Route path="/CosmeticDentistry">
                <CosmeticDentistry></CosmeticDentistry>
              </Route>
              <Route path="/RootCanals">
                <RootCanals />
              </Route>
              <Route path="/Implants">
                <Crowns />
              </Route>
              <Route path="/Emergencies">
                <Emergencies />
              </Route>
              <Route path="/MeetOurTeam">
                <MeetOurTeam />
              </Route>
              <Route path="/Staff">
                <Staff />
              </Route>
              <Route path="/Forms">
                <Forms />
              </Route>
              <Route path="/Insurance">
                <Insurance />
              </Route>
              <Route path="/Contact">
                <Contact />
              </Route>
              <Route path="/Payment">
                <Payment />
              </Route>
              <Route path="/Appointment">
                <AppointmentForm />
              </Route>
              <Route path="/About">
                <About />
              </Route>
              <Route>
                <Main></Main>
              </Route>
            </Switch>
          </Router>
        </div>
        <div className="footer-main" style={{ minHeight: 0 }}>
          <Footer></Footer>
        </div>
      </div>
    );
  }
}

export default App;
