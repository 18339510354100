import React from "react";
import stock from "./Stock/stock16.jpg";
import veneers from "./Icons/veneers.png";
import genden from "./Icons/genden.png";
import { Container, Row, Col, Button } from "react-bootstrap";
import "./App.css";

class CosmeticDentistry extends React.Component {
  render() {
    return (
      <div className="px-1 pb-5" style={{ textAlign: "left" }}>
        <div>
          <img
            src={stock}
            alt="cosmetic-1"
            style={{ borderRadius: "0", width: "100%", height: "auto" }}
          ></img>
        </div>
        <br></br>
        <Container>
          <Row className="m-auto">
            <Col
              xs={9}
              sm={5}
              md={4}
              lg={3}
              xl={3}
              className="mx-auto text-center pb-3"
            >
              <img class="w-100 p-5" src={veneers} />
              <a
                href="https://patientportal-cs5.carestack.com/?dn=stcloud/#/online-appointments"
                className="button appt"
              >
                BOOK AN APPT
              </a>
            </Col>
            <Col xs={12} sm={7} md={8} lg={9} xl={9} className="py-5">
              <h1 className="mb-4" style={{ fontWeight: "300" }}>
                Cosmetic Dentistry
              </h1>
              <p className="pb-3">
                We understand how important it is to not only maintain your
                overall oral health, but to also feel confident and happy when
                you look in the mirror and smile. That’s why you’ll not only
                discover top-notch general and restorative dentistry, but also
                cosmetic dentistry options. We use only the best, most advanced
                technology and high-quality materials to perform our procedures
                at our onsite location, providing you with the added comfort and
                convenience you deserve. Whether you want to get rid of
                unsightly stains or fix chipped or missing teeth, you can trust
                our experts to deliver the beautiful smile makeover you’ve been
                dreaming of for years!
              </p>
              <h6 className="pb-3">
                Our cosmetic dentistry services includes:
              </h6>
              <ul>
                <li>
                  Teeth whitening: If you have stained or discolored teeth,
                  you’ll be thrilled to know we can give you the bright, white
                  smile you want. You can choose to have our professionals
                  whiten your teeth in our office, or you can perform teeth
                  whitening under our direction in the comfort of your home. We
                  can also help recommend over-the-counter teeth whitening
                  products.
                </li>
                <li>
                  Bonding: If you’re dealing with cracked, chipped teeth or you
                  have spaces between your teeth, we can help by applying
                  natural, tooth-colored materials to fill undesirable cracks or
                  spaces. The materials we use will give you back your beautiful
                  smile and are backed by years of research proving their
                  effectiveness.
                </li>
                <li>
                  Reshaping: This service is also known as dental contouring and
                  can be used to align your teeth and give them the attractive
                  shape you want. Typically, this procedure involves
                  modification or removal of the enamel and goes hand-in-hand
                  with bonding.
                </li>
                <li>
                  Lengthening the crown: Do you see more of your gums that you’d
                  like when you smile? With this safe and effective surgical
                  process, you can remove excess gum to expose more crown and
                  give yourself a gorgeous, “toothy” smile.
                </li>
                <li>
                  Smile lifts: This cosmetic procedure combines multiple
                  surgical procedures to change the way your mouth and teeth
                  appear when you smile. Using this procedure, we can help you
                  achieve that beautiful smile you thought was impossible!
                </li>
                <li>
                  Veneers: Have unsightly imperfections and discoloration you’d
                  love to hide from the world? No problem. With our
                  custom-crafted and custom-fit porcelain shell veneers, you can
                  cover up your existing teeth and show the world your best
                  possible smile!
                </li>
              </ul>

              <p>
                Cosmetic dentistry can help you achieve results you never even
                dreamed were possible with an incredible, perfect smile that
                gives you back your confidence. However, it’s extremely
                important to choose an experienced cosmetic dentist for the best
                results. Our experience and top-notch technology to ensure you
                get the long-lasting, transformational results you want.
              </p>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default CosmeticDentistry;
