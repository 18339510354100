import React from "react";
import "./App.css";
import { Container, Row, Col } from "react-bootstrap";
import rootcanal from "./Icons/rootcanal.png";
import stock from "./Stock/stock8.jpg";

class RootCanals extends React.Component {
  render() {
    return (
      <div className="px-1 pb-5" style={{ textAlign: "left" }}>
        <div>
          <img
            src={stock}
            alt="root-canal-1"
            style={{ borderRadius: "0", width: "100%", height: "auto" }}
          ></img>
        </div>
        <br></br>
        <Container>
          <Row className="m-auto">
            <Col
              xs={9}
              sm={5}
              md={4}
              lg={3}
              xl={3}
              className="mx-auto text-center pb-3"
            >
              <img class="w-100 p-5" src={rootcanal} />
              <a
                href="https://patientportal-cs5.carestack.com/?dn=stcloud/#/online-appointments"
                className="button appt"
              >
                <span></span>BOOK AN APPT
              </a>
            </Col>
            <Col xs={12} sm={9} md={9} lg={9} xl={9} className="py-5">
              <h1 className="mb-4" style={{ fontWeight: "300" }}>
                Root Canals
              </h1>
              <p className="pb-3">
                Root canal treatment is completed in one or two office visits,
                depending on the presence of infection and the required
                treatment plan. After restoration, the tooth continues to
                function like any other tooth. With good oral hygiene, the
                restored tooth has the potential to last a lifetime.
              </p>
              <h6 className="pb-3">What are some commons symptoms?</h6>
              <ul>
                <li>
                  Tooth or gum pain, discoloration, or prolonged sensitivity to
                  heat or cold
                </li>
                <li>A tooth that is tender when it is touched</li>
                <li>
                  A tooth abscess (pus enclosed in the tissues of the jawbone at
                  the tip of an infected tooth)
                </li>
                <li>Pus drainage in your mouth</li>
                <li>
                  Swelling or tenderness in the lymph nodes under your jaw
                </li>
              </ul>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default RootCanals;
