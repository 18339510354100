import React from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { withRouter } from "react-router-dom";
import "./App.css";
// Appointment date: can we restrict it to where you can't chose days where the office is closed?
// Appointment time: min/max does not seem to be working
class AppointmentRequest extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      email: "",
      number: "",
      doctor: "",
      date: "",
      time: "",
      comments: "",
      validName: true,
      validEmail: true,
      validNumber: true,
    };
  }

  handleNameChange = (event) => {
    this.setState({
      name: event.target.value,
    });
  };

  handleEmailChange = (event) => {
    this.setState({
      email: event.target.value,
    });
  };

  handleNumberChange = (event) => {
    const formattedPhoneNumber = this.formatPhoneNumber(event.target.value);
    this.setState({
      number: formattedPhoneNumber,
    });
  };

  handleDrChange = (event) => {
    this.setState({
      doctor: event.target.value,
    });
  };

  handleDateChange = (event) => {
    this.setState({
      date: event.target.value,
    });
  };

  handleTimeChange = (event) => {
    this.setState({
      time: event.target.value,
    });
    console.log(this.state.time);
  };

  handleCommentsChange = (event) => {
    this.setState({
      comments: event.target.value,
    });
  };

  handleSubmit = (event) => {
    event.preventDefault();
    const { name, email, number } = this.state;
    if (!this.nameValidation(name)) {
      this.setState({ validName: false });
    }
    if (!this.emailValidation(email)) {
      this.setState({ validEmail: false });
    }
    if (!this.numberValidation(number)) {
      this.setState({ validNumber: false });
    }
    if (
      this.nameValidation(name) &&
      this.emailValidation(email) &&
      this.numberValidation(number)
    ) {
      this.props.history.push("/");
    }
    console.log(name, email, number);
  };

  formatPhoneNumber(value) {
    // if input value is falsy eg if the user deletes the input, then just return
    if (!value) return value;
    const phoneNumber = value.replace(/[^\d]/g, "");
    const phoneNumberLength = phoneNumber.length;

    if (phoneNumberLength < 4) return phoneNumber;

    if (phoneNumberLength < 7) {
      return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
    }

    return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(
      3,
      6
    )}-${phoneNumber.slice(6, 10)}`;
  }

  nameValidation(name) {
    if (name.length > 2) {
      return true;
    } else {
      return false;
    }
  }

  emailValidation(email) {
    const regex =
      /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    if (!email || regex.test(email) === false) {
      return false;
    } else {
      return true;
    }
  }

  numberValidation(number) {
    if (number.length === 14) {
      return true;
    } else {
      return false;
    }
  }

  render() {
    const {
      name,
      email,
      number,
      numberFormat,
      doctor,
      date,
      time,
      comments,
      validName,
      validEmail,
      validNumber,
    } = this.state;
    return (
      <div className="p-5" style={{ textAlign: "left" }}>
        <div className="form-header mt-4">
          <p>REQUEST AN APPOINTMENT</p>
        </div>
        <h4 className="text-center">How can we help you?</h4>
        <Form className="form" onSubmit={this.handleSubmit}>
          <Form.Group className="mb-3">
            <Form.Label>Name</Form.Label>
            <Form.Control
              required
              type="name"
              value={name}
              onChange={this.handleNameChange}
              placeholder="Enter name"
            ></Form.Control>
            <span className="form-error" hidden={validName}>
              Enter a valid name
            </span>
          </Form.Group>
          <Form.Group className="mb-3" controlId="validationCustom02">
            <Form.Label>Email</Form.Label>
            <Form.Control
              required
              type="text"
              value={email}
              onChange={this.handleEmailChange}
              placeholder="Enter email"
            ></Form.Control>
            <span className="form-error" hidden={validEmail}>
              Enter a valid email
            </span>
          </Form.Group>
          <Form.Group className="mb-3" controlId="validationCustom03">
            <Form.Label>Number</Form.Label>
            <Form.Control
              required
              type="text"
              value={number}
              onChange={this.handleNumberChange}
              placeholder="Enter phone number"
            ></Form.Control>
            <span className="form-error" hidden={validNumber}>
              Enter a valid number
            </span>
          </Form.Group>
          <Form.Group className="mb-3" controlId="validationCustom04">
            <Form.Label>Preferred Appointment Date:</Form.Label>
            <Form.Control
              required
              type="date"
              value={date}
              onChange={this.handleDateChange}
              placeholder="Preferred Date: mm/dd/yy*"
            ></Form.Control>
            <span className="form-error" hidden="true">
              Enter a valid number
            </span>
          </Form.Group>
          <Form.Group className="mb-3" controlId="validationCustom05">
            <Form.Label>Preferred Appointment Time:</Form.Label>
            <Form.Control
              required
              type="time"
              value={time}
              min="19:00"
              max="05:00"
              onChange={this.handleTimeChange}
              placeholder="Preferred Date: mm/dd/yy*"
            ></Form.Control>
            <span className="form-error" hidden="true">
              Enter a valid number
            </span>
          </Form.Group>
          <Form.Group className="mb-3" controlId="validationCustom06">
            <Form.Label>Additional Comments</Form.Label>
            <Form.Control
              required
              type="text"
              value={comments}
              rows="5"
              cols="40"
              onChange={this.handleCommentChange}
              placeholder="Enter additional comments"
            ></Form.Control>
            <span className="form-error" hidden="true">
              Enter a valid number
            </span>
          </Form.Group>
          <div className="text-center">
            <Button
              variant="no-border"
              style={{
                color: "white",
                backgroundColor: "#00adf2",
              }}
              type="submit"
            >
              Submit
            </Button>
          </div>
        </Form>
      </div>
    );
  }
}

export default withRouter(AppointmentRequest);
