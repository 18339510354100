import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import angela from "./Staff/angela.jpg";
import christopher from "./Staff/christopher.jpg";
import cynthia from "./Staff/cynthia.jpg";
import helen from "./Staff/helen.jpg";
import jojo from "./Staff/jojo.jpg";
import vita from "./Staff/vita.jpg";
import katherine from "./Staff/katherine.jpg";
import kc from "./Staff/kc.jpg";
// import debbie from "./Staff/debbie.jpg";
import alexandra from "./Staff/alex.jpg";

class Staff extends React.Component {
  render() {
    return (
      <div>
        <Container>
          <h1>Meet Our Staff</h1>
          <Row className="mx-auto my-5" style={{ width: "100%" }}>
            <Col xs={12} sm={6} md={6} lg={6} xl={4}>
              <img
                className="p-4"
                src={vita}
                alt="Vita, Office Manager"
                style={{
                  backgroundImage:
                    "linear-gradient(315deg, #ffffff 0%, #d7e1ec 74%)",
                  borderRadius: "0",
                  width: "100%",
                  height: "auto",
                }}
              ></img>
            </Col>
            <Col xs={12} sm={6} md={6} lg={6} xl={8}>
              <div className="px-1 py-4">
                <h3 className="pb-4">Vita, Office Manager</h3>
                <p>
                  Originally from New Jersey, Vita happily transplanted to St.
                  Cloud in 2004. She has one daughter who graduated from Harmony
                  High School. She has been a Florida Notary since 2008 and
                  throughout her career, she has always been in management or accounting.
                  She holds an AA in Criminal Justice and has a passion for
                  helping others. She never thought she'd be working in dental,
                  especially for almost a decade, but she wouldn't change a thing.
                  Not only does she enjoy learning new things on a daily basis,
                  she gained a family she didn't know she needed. If she's not
                  cooking or gardening, you can find her fishing or enjoying
                  the beautiful beaches of Anna Maria Island with her dog, Chi.
                </p>
              </div>
            </Col>
          </Row>
          <Row className="mx-auto my-5" style={{ width: "100%" }}>
            <Col xs={12} sm={6} md={6} lg={6} xl={4}>
              <img
                className="p-4"
                src={angela}
                alt="Angela, Assistant Manager"
                style={{
                  backgroundImage:
                    "linear-gradient(315deg, #ffffff 0%, #d7e1ec 74%)",
                  borderRadius: "0",
                  width: "100%",
                  height: "auto",
                }}
              ></img>
            </Col>
            <Col xs={12} sm={6} md={6} lg={6} xl={8}>
              <div className="px-1 py-4">
                <h3 className="pb-4">Angela, Assistant Manager</h3>
                <p>
                  Angela is our treatment coordinator and assistant manager who
                  has been working at St. Cloud Smiles since 2015. Prior to
                  working here, she had no dental knowledge, but she had medical
                  knowledge. She started at the front desk and worked hard,
                  learning the ins and outs of the industry. Angela moved to
                  Florida in 2015 from Savannah, Georgia with her family and now
                  loves it here. She loves working with patients and being able
                  to facilitate the patients' office experience to ensure they
                  leave well-informed and prepared for their next appointment.
                </p>
              </div>
            </Col>
          </Row>
          <Row className="mx-auto my-5" style={{ width: "100%" }}>
            <Col xs={12} sm={6} md={6} lg={6} xl={4}>
              <img
                className="p-4"
                src={jojo}
                alt="Jojo, Lead Dental Assistant"
                style={{
                  backgroundImage:
                    "linear-gradient(315deg, #ffffff 0%, #d7e1ec 74%)",
                  borderRadius: "0",
                  width: "100%",
                  height: "auto",
                }}
              ></img>
            </Col>
            <Col xs={12} sm={6} md={6} lg={6} xl={8}>
              <div className="px-1 py-4">
                <h3 className="pb-4">Yahaira (JoJo), Lead Dental Assistant</h3>
                <p>
                  JoJo was born in the Dominican Republic. When she was 3 years
                  old, she and her family immigrated to America. She has been in
                  the dental field since 2013 when she graduated from dental
                  assisting school. Her goal is to provide the best dental
                  treatments possible to all her patients. When she is not
                  helping patients in the office, she enjoys time with her
                  husband and 2 children, who are dedicated to baseball and
                  volleyball. JoJo has been with St. Cloud Smiles since 2014!
                </p>
              </div>
            </Col>
          </Row>
          <Row className="mx-auto my-5" style={{ width: "100%" }}>
            <Col xs={12} sm={6} md={6} lg={6} xl={4}>
              <img
                className="p-4"
                src={kc}
                alt="KC, Dental Assistant"
                style={{
                  backgroundImage:
                    "linear-gradient(315deg, #ffffff 0%, #d7e1ec 74%)",
                  borderRadius: "0",
                  width: "100%",
                  height: "auto",
                }}
              ></img>
            </Col>
            <Col xs={12} sm={6} md={6} lg={6} xl={8}>
              <div className="px-1 py-4">
                <h3 className="pb-4">KC, Dental Assistant</h3>
                <p>
                  KC is our newest, most enthusiastic dental assistant since
                  2019! She feels particularly privileged to work with our
                  Doctors, Dr. Van and Dr. Ball. Dental has always been one of
                  her passions. She loves to be able to help patients transform
                  their smiles to make them feel comfortable. In her free time
                  she loves going to the beach and spending time with her
                  family.
                </p>
              </div>
            </Col>
          </Row>
          <Row className="mx-auto my-5" style={{ width: "100%" }}>
            <Col xs={12} sm={6} md={6} lg={6} xl={4}>
              <img
                className="p-4"
                src={christopher}
                alt="Christopher, Dental Assistant"
                style={{
                  backgroundImage:
                    "linear-gradient(315deg, #ffffff 0%, #d7e1ec 74%)",
                  borderRadius: "0",
                  width: "100%",
                  height: "auto",
                }}
              ></img>
            </Col>
            <Col xs={12} sm={6} md={6} lg={6} xl={8}>
              <div className="px-1 py-4">
                <h3 className="pb-4">Christopher, Dental Assistant</h3>
                <p>
                  Christopher is from the Dominican Republic, was raised in
                  Manhattan, New York, and is a native to central Florida. He is
                  continuously learning and always appreciating every moment on
                  his journey as a student of dentistry. He's happily wedded to
                  Marlene and has a child named Jayden. Christopher and his
                  better half enjoy investigating new journeys outside the
                  country and love getting a taste of every plant-based cuisine
                  as they travel. In his free time, he appreciates exploring,
                  understanding books, cultivating, cooking, painting, and
                  working out, whether it's through yoga, running, basketball,
                  or baseball with his child.
                </p>
              </div>
            </Col>
          </Row>
          <Row className="mx-auto my-5" style={{ width: "100%" }}>
            <Col xs={12} sm={6} md={6} lg={6} xl={4}>
              <img
                className="p-4"
                src={helen}
                alt="Helen, Dental Liaison"
                style={{
                  backgroundImage:
                    "linear-gradient(315deg, #ffffff 0%, #d7e1ec 74%)",
                  borderRadius: "0",
                  width: "100%",
                  height: "auto",
                }}
              ></img>
            </Col>
            <Col xs={12} sm={6} md={6} lg={6} xl={8}>
              <div className="px-1 py-4">
                <h3 className="pb-4">Helen, Dental Liaison</h3>
                <p>
                  As a teenager, Helen was transplanted here all the way from
                  the Pocono Mountains in Pennsylvania. Helen graduated from
                  Harmony High School in 2010 where she enjoyed being an
                  Athletic Trainer. Helen has been with St. Cloud Smiles for 7
                  years and has a true passion for helping others. Her role as a
                  Dental Liaison is a perfect match; she enjoys working closely
                  with our staff and patients building relationships. She was
                  blessed to marry her best friend in 2018 and gained two
                  awesome step-sons at the same time. Her family means the world
                  to her. In her spare time she loves spending time with her
                  family, playing video games, and enjoying the Florida
                  sunshine.
                </p>
              </div>
            </Col>
          </Row>
          <Row className="mx-auto my-5" style={{ width: "100%" }}>
            <Col xs={12} sm={6} md={6} lg={6} xl={4}>
              <img
                className="p-4"
                src={katherine}
                alt="Katherine, Dental Hygienist"
                style={{
                  backgroundImage:
                    "linear-gradient(315deg, #ffffff 0%, #d7e1ec 74%)",
                  borderRadius: "0",
                  width: "100%",
                  height: "auto",
                }}
              ></img>
            </Col>
            <Col xs={12} sm={6} md={6} lg={6} xl={8}>
              <div className="px-1 py-4">
                <h3 className="pb-4">Katherine, Dental Hygienist</h3>
                <p>
                  Katherine graduated as a general dentist in her home county,
                  Venezuela. She moved to the US and has been living here for 5
                  years now, working as a dental hygienist providing the best
                  oral care. Katherine enjoys taking care of her patients and
                  making them feel comfortable in the chair. In her free time,
                  she enjoys being with family and friends, traveling, reading,
                  and learning more every day.
                </p>
              </div>
            </Col>
          </Row>
          <Row className="mx-auto my-5" style={{ width: "100%" }}>
            <Col xs={12} sm={6} md={6} lg={6} xl={4}>
              <img
                className="p-4"
                src={cynthia}
                alt="Cynthia, Dental Hygienist"
                style={{
                  backgroundImage:
                    "linear-gradient(315deg, #ffffff 0%, #d7e1ec 74%)",
                  borderRadius: "0",
                  width: "100%",
                  height: "auto",
                }}
              ></img>
            </Col>
            <Col xs={12} sm={6} md={6} lg={6} xl={8}>
              <div className="px-1 py-4">
                <h3 className="pb-4">Cynthia, Dental Hygienist</h3>
                <p>
                  Cynthia has been in the dental field for almost three decades.
                  Half of that time, she was a general dentist in her home
                  county. She continued on as a hygienist in the US to provide
                  care to people the best way she knows how-- through oral care.
                  When she's not busy in the dental world, Cynthia loves
                  spending time with her family: her husband, two sons, and
                  their dog. She loves to draw and paint, enjoy the outdoors,
                  and play sports. But more often than not, you can find her in
                  the front row of a Zumba class at the local gym!
                </p>
              </div>
            </Col>
          </Row>
          <Row className="mx-auto pb-4" style={{ width: "100%" }}>
            <Col xs={12} sm={6} md={6} lg={6} xl={4}>
              <img
                className="p-4"
                src={alexandra}
                alt="Alexandra, Dental Hygienist"
                style={{
                  backgroundImage:
                    "linear-gradient(315deg, #ffffff 0%, #d7e1ec 74%)",
                  borderRadius: "0",
                  width: "100%",
                  height: "auto",
                }}
              ></img>
            </Col>
            <Col xs={12} sm={6} md={6} lg={6} xl={8}>
              <div className="px-1 py-4">
                <h3 className="pb-4">Alexandra, Dental Hygienist</h3>
                <p>
                  In 1996, Alexandra Cedeno graduated as a Dentist in Venezuela.
                  She received her post-graduate degree in 2003, and practiced
                  the profession for 15 years prior to emigrating to the United
                  States. Licensed as a Dental Hygienist in Florida in 2014, she
                  seeks, at all times and with all patients, to pursue her work
                  with professionalism and excellence, always striving to make
                  them feel as relaxed and safe as possible. Her passion
                  includes educating patients of all ages about dental care,
                  good hygiene, and eating habits so they may avoid oral
                  problems in the future. Outside the dental field, Alexandra
                  dedicates her time to caring for and spending time with her
                  family. She also enjoys reading, walking outdoors, and the
                  beach.
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default Staff;
