import React from "react";

class Forms extends React.Component {
  render() {
    return (
      <div className="p-5" style={{ textAlign: "left" }}>
        <h1>New Patient Forms</h1>
        <ul>
          <li>New Patient Form</li>
          <li>HIPAA Release Form</li>
          <li>HIPAA Notice of Privacy Practices</li>
          <li>Consent for Email and Text</li>
          <li>Appointment and Cancellation Policy</li>
        </ul>
      </div>
    );
  }
}

export default Forms;
