import React from "react";
import Button from "react-bootstrap/Button";
import image1 from "./Stock/stock4.jpg";
import image2 from "./Images/storefront.jpg";
import image3 from "./Stock/stock15.jpg";

import "bootstrap/dist/css/bootstrap.css";
import Carousel from "react-bootstrap/Carousel";

export default function App() {
  return (
    <div className="px-1" style={{ display: "block" }}>
      <Carousel wrap={false}>
        <Carousel.Item>
          <img className="d-block w-100" src={image1} alt="img-1" />
          <Carousel.Caption>
            <h3>Healthy smiles start here</h3>
            <p>Book your appointment today!</p>
            <Button
              href="https://patientportal-cs5.carestack.com/?dn=stcloud/#/online-appointments"
              variant="outline-light"
            >
              BOOK APPT
            </Button>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img className="d-block w-100" src={image2} alt="img-2" />
          <Carousel.Caption>
            <h3>2050 Old Hickory Tree Rd., St. Cloud, FL 34772</h3>
            <p>
              We are conveniently located on Old Hickory Tree Road and 10th
              Street, off of Highway 192.
            </p>
            <Button
              href="https://patientportal-cs5.carestack.com/?dn=stcloud/#/online-appointments"
              variant="outline-light"
            >
              BOOK APPT
            </Button>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img className="d-block w-100" src={image3} alt="Image Three" />
          <Carousel.Caption>
            <h3>Emergency care available</h3>
            <p>Call us for more information</p>
          </Carousel.Caption>
        </Carousel.Item>
      </Carousel>
    </div>
  );
}
