import React from "react";
import "./App.css";
import { Container, Row, Col } from "react-bootstrap";
import stock from "./Stock/stock13.jpg";
import crowns from "./Icons/crowns.png";

class Crowns extends React.Component {
  render() {
    return (
      <div className="px-1 pb-5" style={{ textAlign: "left" }}>
        <div>
          <img
            src={stock}
            alt="crown"
            style={{ borderRadius: "0", width: "100%", height: "auto" }}
          ></img>
        </div>
        <br></br>
        <Container>
          <Row className="m-auto">
            <Col
              xs={9}
              sm={5}
              md={4}
              lg={3}
              xl={3}
              className="mx-auto text-center pb-3"
            >
              <img class="w-100 p-5" src={crowns} />
              <a
                href="https://patientportal-cs5.carestack.com/?dn=stcloud/#/online-appointments"
                className="button appt"
              >
                BOOK AN APPT
              </a>
            </Col>
            <Col xs={12} sm={9} md={9} lg={9} xl={9} className="py-5">
              <h1 className="mb-4" style={{ fontWeight: "300" }}>
                Dental Implants
              </h1>
              <p className="pb-3">
                Problems like physical injury, tooth decay, dental cavities, and
                gum disease can all result in a loss of teeth. Missing teeth
                create gaps that can make you self-conscious about your smile
                and lower your self-esteem. You may have trouble speaking
                clearly if you have multiple gaps in your teeth, and you might
                not even be able to chew your food easily. In order to improve
                your quality of life and give you back a happy, healthy,
                confident smile, we offer the best dental implant services
                available. With dental implants, you can get back a full,
                flawless smile you love! Our dental implants are used to support
                dental prosthetics and/or artificial teeth.
                <br></br>
                <br></br>
                Here’s how teeth replacement works:
                <br></br>
                Our experienced professionals will use modern technology to
                install an implant that acts as the roots of a natural tooth.
                This provides a strong foundation for either permanent teeth or
                removable dentures.
              </p>
              <h6 className="pb-3">With dental implants you can enjoy:</h6>
              <ul>
                <li>
                  Greater ease in chewing your food with strong, durable
                  implants
                </li>
                <li>Improved speech quality</li>
                <li>Better self-esteem</li>
                <li>A beautiful smile that fills you with confidence</li>
                <li>And more...</li>
              </ul>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default Crowns;
