import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import "./App.css";
import stock from "./Stock/stock9.jpg";
import genden from "./Icons/genden.png";
import cleaning from "./Icons/cleaning.png";
import fillings from "./Icons/fillings.png";

class GeneralDentistry extends React.Component {
  render() {
    return (
      <div className="px-1 pb-5" style={{ textAlign: "left" }}>
        <div>
          <img
            src={stock}
            alt="smiling-1"
            style={{ borderRadius: "0", width: "100%", height: "auto" }}
          ></img>
        </div>
        <br></br>
        <Container>
          <Row className="m-auto">
            <Col
              xs={9}
              sm={5}
              md={4}
              lg={3}
              xl={3}
              className="mx-auto text-center pb-3"
            >
              <img class="w-100 p-5" src={genden} />
              <a
                href="https://patientportal-cs5.carestack.com/?dn=stcloud/#/online-appointments"
                className="button appt"
              >
                BOOK AN APPT
              </a>
            </Col>
            <Col xs={12} sm={9} md={9} lg={9} xl={9} className="py-5">
              <h1 className="mb-4" style={{ fontWeight: "300" }}>
                Routine Dental Checkups
              </h1>
              <p className="pb-3">
                Routine dental checkups are vital to good oral hygiene and are
                the best way to detect problems at their earliest stages. Early
                detection and treatment saves unnecessary discomfort, time and
                money. The Academy of General Dentistry recommends checkups
                twice a year. Oral hygiene needs to change with age and the
                condition of your teeth and gums. Your dentist may recommend a
                checkup schedule that is more or less frequent than twice a
                year.
              </p>
              <h6 className="mb-4">Your dental checkup includes:</h6>
              <ul>
                <li>A visual exam of your mouth, face, jaw and neck</li>
                <li>Digital diagnostics, as determined by your dentist</li>
                <li>A gingival pocket exam</li>
                <li>Professional cleaning of your teeth</li>
                <li>
                  Comprehensive data captured in an electronic dental record
                </li>
                <li>A treatment plan giving you options that fit your needs</li>
              </ul>
            </Col>
          </Row>
        </Container>
        <Container>
          <Row className="m-auto">
            <Col
              xs={9}
              sm={5}
              md={4}
              lg={3}
              xl={3}
              className="mx-auto text-center pb-3"
            >
              <img class="w-100 p-5" src={cleaning} />
              <a
                href="https://patientportal-cs5.carestack.com/?dn=stcloud/#/online-appointments"
                className="button appt"
              >
                BOOK AN APPT
              </a>
            </Col>
            <Col xs={12} sm={9} md={9} lg={9} xl={9} className="py-5">
              <h1 className="mb-4" style={{ fontWeight: "300" }}>
                Professional Cleaning
              </h1>
              <p>
                Your teeth are cleaned using special instruments to remove
                plaque from above and below the gumline. Afterwards, your teeth
                are polished. Tooth polishing makes your teeth look and feel
                great. It also smoothes tooth surfaces so plaque is less likely
                to accumulate.
              </p>
            </Col>
          </Row>
        </Container>
        <Container>
          <Row className="m-auto">
            <Col
              xs={9}
              sm={5}
              md={4}
              lg={3}
              xl={3}
              className="mx-auto text-center pb-3"
            >
              <img class="w-100 p-5" src={fillings} />
              <a
                href="https://patientportal-cs5.carestack.com/?dn=stcloud/#/online-appointments"
                className="button appt"
              >
                BOOK AN APPT
              </a>
            </Col>
            <Col xs={12} sm={9} md={9} lg={9} xl={9} className="py-4">
              <h1 className="mb-4" style={{ fontWeight: "300" }}>
                Composite Fillings
              </h1>
              <p>
                A filling repairs and restores the surface of a tooth that has
                been damaged by decay, fracture, or wear. A dental filling
                strengthens the tooth. If tooth decay is not repaired at its
                early stages, it will worsen and additional or alternative
                dental treatments may be necessary. With proper care and routine
                oral hygiene, a filling has a lifespan of 5-12 years, depending
                upon the type of filling material used.
              </p>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default GeneralDentistry;
