import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import card from "./Icons/debitcredit.png";
import savings from "./Icons/savings.png";

class Payment extends React.Component {
  render() {
    return (
      <div className="pt-4 pb-5" style={{ textAlign: "left" }}>
        <Container>
          <Row className="pb-3">
            <h1>Payment</h1>
          </Row>
          <Row>
            <p>
              {" "}
              Paying for your health care should be easy and painless. To
              accommodate all our patients, we accept a variety of payment
              methods.
            </p>
          </Row>
        </Container>

        <Container className="p-0">
          <Row style={{ height: "auto" }}>
            <Col className="m-3 p-5" style={{ backgroundColor: "#e5e5e5" }}>
              <img
                src={card}
                style={{ borderRadius: "0", width: "auto", height: "150px" }}
              ></img>
              <h5 className="mt-4">Debit and Credit Cards</h5>
              <p>
                We accept all major credit cards, including Visa, MasterCard,
                Discover, and American Express. All debit cards are accepted.
              </p>
              <h5>CareCredit</h5>
              <p>
                {" "}
                CareCredit is a healthcare credit card that is used to pay for
                out-of-pocket expenses not covered by dental insurance. Our
                staff is well-trained to help you navigate on CareCredit website
                to get you started. They can take you from application to
                approval and onto to using it for your dental needs.{" "}
              </p>
              <h5>Cash</h5>
            </Col>
            <Col className="m-3 p-5" style={{ backgroundColor: "#e5e5e5" }}>
              <img
                src={savings}
                style={{ borderRadius: "0", width: "auto", height: "150px" }}
              ></img>
              <h5 className="mt-4">St. Cloud Smiles Cares Savings Plan</h5>
              <ul>
                <li>Basic Plan</li>
                <li>Care Plan</li>
              </ul>
              <h5>CareCredit</h5>
              <p>
                {" "}
                CareCredit is a healthcare credit card that is used to pay for
                out-of-pocket expenses not covered by dental insurance. Our
                staff is well-trained to help you navigate on CareCredit website
                to get you started. They can take you from application to
                approval and onto to using it for your dental needs.{" "}
              </p>
              <h5>HSA/FSA</h5>
            </Col>
          </Row>
        </Container>
        <div className="mt-5 mx-2">
          <h5>Payment Plans</h5>
          <p>
            Contact a staff member at our practice to learn more about financing
            options to manage your dentistry costs.
          </p>
        </div>
      </div>
    );
  }
}

export default Payment;
