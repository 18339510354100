import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";

class MissionStatement extends React.Component {
  render() {
    return (
      <Container className="pb-3">
        <Row className="">
          <h4>Welcome to St. Cloud Smiles!</h4>
          <p>
            St. Cloud Smiles is a general and cosmetic dentistry providing the
            finest dental care at reasonable costs. Our dentists and hygienists
            have the extensive experience needed to meet your particularized
            needs. We take pride in providing the best and most gentle dental
            experience to each and every patient. We offer a variety of services
            to fit the needs of you and your family. We proudly serve the St.
            Cloud area and are dedicated to serving our patients with excellence
            and compassionate care. Call us at (407) 556-3969 or use our online
            appointment requester to see one of our dentists today!
          </p>
        </Row>
        <Row className="pt-3 pb-5">
          <h4>Our Philosophy</h4>
          <p>
            At St. Cloud Smiles, we believe that your oral health is paramount.
            We are equipped with state of the art equipment and most up to date
            training to provide comprehensive dental care to you. You can rest
            assured that we will only give standard of care dental procedures
            for your needs.
          </p>
        </Row>
      </Container>
    );
  }
}
export default MissionStatement;
