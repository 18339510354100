import React, { useState } from "react";
import logo from "./Logo/Logo Final.JPG";
import menu from "./Icons/menu.png";
import "./styles.css";
import "./App.css";
import { Container, Row, Col, Button } from "react-bootstrap";
import { ProSidebar, Menu, MenuItem, SubMenu } from "react-pro-sidebar";
import "react-pro-sidebar/dist/css/styles.css";
// Logo & Appt request button does not align
// Appt request button does not link to proper page

class NavComponent extends React.Component {
  constructor() {
    super();
    this.state = {
      width: window.innerWidth,
      isNavExpanded: false,
      setIsNavExpanded: false,
    };
    this.handleMenuClick = this.handleMenuClick.bind(this);
  }

  componentWillMount() {
    window.addEventListener("resize", this.handleWindowSizeChange);
  }

  // make sure to remove the listener
  // when the component is not mounted anymore
  componentWillUnmount() {
    window.removeEventListener("resize", this.handleWindowSizeChange);
  }

  handleWindowSizeChange = () => {
    this.setState({ width: window.innerWidth });
  };

  handleMenuClick() {
    this.setState({ isNavExpanded: !this.state.isNavExpanded });
  }

  render() {
    const { width, isNavExpanded } = this.state;
    const isMobile = width <= 900;
    console.log(isNavExpanded);

    if (isMobile) {
      return (
        <nav className="navigation">
          <a href="/">
            <img className="photo" src={logo}></img>
          </a>
          <a onClick={this.handleMenuClick}>
            {/* icon from Heroicons.com */}
            <img className="hamburger" src={menu}></img>
          </a>
          <div
            className={
              isNavExpanded ? "navigation-menu expanded" : "navigation-menu"
            }
          >
            <ul>
              <li>
                <a href="/About">About</a>
              </li>
              <li>
                <a href="/GeneralDentistry">General Dentistry</a>
              </li>
              <li>
                <a href="/CosmeticDentistry">Cosmetic Dentistry</a>
              </li>
              <li>
                <a href="/RootCanals">Dental Implants</a>
              </li>
              <li>
                <a href="/Implants">Root Canals</a>
              </li>
              <li>
                <a href="/Insurance">Insurance</a>
              </li>
              <li>
                <a href="/Payment">Payment</a>
              </li>
              <li>
                <a href="/MeetOurTeam">Dentists</a>
              </li>
              <li>
                <a href="/Staff">Staff</a>
              </li>
              <li>
                <a href="/Emergencies">Emergencies</a>
              </li>
              <li>
                <a href="/Contact">Contact</a>
              </li>
              <li>
                <a href="https://patientportal-cs5.carestack.com/?dn=stcloud/#/online-appointments">
                  Request Appointment
                </a>
              </li>
              <li>
                <a href="https://patientportal-cs5.carestack.com/?dn=stcloud">
                  Patient Forms
                </a>
              </li>
            </ul>
          </div>
        </nav>
      );
    } else {
      return (
        <div className="header">
          <a href="/">
            <img className="photo" src={logo}></img>
          </a>
          <div className="nav" style={{ paddingTop: "100px", height: "150px" }}>
            <div className="dropdown">
              <a href="/About" className="dropbtn">
                ABOUT
              </a>
            </div>
            <div className="dropdown">
              <a href="GeneralDentistry" className="dropbtn">
                SERVICES
              </a>
              <div className="dropdown-content">
                <a href="GeneralDentistry">GENERAL DENTISTRY</a>
                <a href="CosmeticDentistry">COSMETIC DENTISTRY</a>
                <a href="Implants">DENTAL IMPLANTS</a>
                <a href="RootCanals">ROOT CANALS</a>
                <a href="Emergencies">EMERGENCIES</a>
              </div>
            </div>
            <div className="dropdown">
              <a href="Insurance" className="dropbtn">
                INSURANCE
              </a>
            </div>
            <div className="dropdown">
              <a href="Payment" className="dropbtn">
                PAYMENT
              </a>
            </div>
            <div className="dropdown">
              <a href="MeetOurTeam" className="dropbtn">
                TEAM
              </a>
              <div className="dropdown-content">
                <a href="MeetOurTeam">DENTISTS</a>
                <a href="Staff">STAFF</a>
              </div>
            </div>
            <div className="dropdown">
              <a href="Contact" className="dropbtn">
                CONTACT
              </a>
              <div className="dropdown-content">
                <a href="https://patientportal-cs5.carestack.com/?dn=stcloud/#/online-appointments">
                  REQUEST AN APPT
                </a>
                <a href="https://patientportal-cs5.carestack.com/?dn=stcloud">
                  PATIENT FORMS
                </a>
                <a href="Contact">CONTACT US</a>
              </div>
            </div>
            <div
              style={{
                position: "absolute",
                right: "5em",
                top: 25,
                fontWeight: 400,
                fontSize: "20px",
                textDecoration: "none",
              }}
            >
              <span className="mx-2"> Call Us:</span>
              <a class="phone" href="tel://+14075563969">
                (407) 556-3969
              </a>
            </div>
          </div>
        </div>
      );
    }
  }
}

export default NavComponent;
