import React from "react";
import Map from "./Map";
import MissionStatement from "./MissionStatement";
import OfficeHours from "./OfficeHours";
import storefront from "./Images/storefront.jpg";
import "./App.css";

class About extends React.Component {
  render() {
    return (
      <div className="text-center">
        <div className="px-5 pt-5 pb-4">
          <img
            class="m-auto w-50"
            style={{ display: "block" }}
            src={storefront}
          ></img>
        </div>
        <div className="m-auto w-75">
          <p>
            St. Cloud Smiles performs general and cosmetic dentistry while
            providing the finest dental care at reasonable costs. Our dentists
            and hygienists have the extensive experience needed to meet your
            particularized needs. We take pride in providing the best and most
            gentle dental experience to each and every patient. We offer a
            variety of services to fit the needs of you and your family. We
            proudly serve the St. Cloud area and are dedicated to serving our
            patients with excellence and compassionate care. Call us at (407)
            556-3969 or use our online appointment requester to see one of our
            dentists today!
          </p>
        </div>
        <div>
          <a
            href="https://patientportal-cs5.carestack.com/?dn=stcloud/#/online-appointments"
            className="button appt m-4"
          >
            BOOK AN APPT
          </a>
        </div>
        <div className="p-0 m-0">
          <OfficeHours />
        </div>
      </div>
    );
  }
}

export default About;
