import React from "react";
import "./App.css";
import { Container, Row, Col } from "react-bootstrap";
import stock from "./Stock/stock14.jpg";
import emergency from "./Icons/emergency.png";

class Emergencies extends React.Component {
  render() {
    return (
      <div className="px-1 pb-5">
        <div>
          <img
            src={stock}
            alt="emergency-1"
            style={{ borderRadius: "0", width: "100%", height: "auto" }}
          ></img>
        </div>
        <br></br>
        <Container>
          <Row className="m-auto">
            <Col
              xs={9}
              sm={5}
              md={4}
              lg={3}
              xl={3}
              className="mx-auto text-center pb-3"
            >
              <img class="w-100 p-5" src={emergency} />
              <a
                href="https://patientportal-cs5.carestack.com/?dn=stcloud/#/online-appointments"
                className="button appt"
              >
                BOOK AN APPT
              </a>
            </Col>
            <Col xs={12} sm={9} md={9} lg={9} xl={9} className="py-5">
              <h1 className="mb-4" style={{ fontWeight: "300" }}>
                Dental Emergencies
              </h1>
              <p className="pb-3">
                We are open for emergency dental care to you whether you are a
                patient of record or not. We understand that dental emergencies
                can occur swiftly and without warning, and can be extremely
                painful. If you have any dental concerns, we are here to help
                you get the treatment you need. Please call with any questions.
                Pain is not the only way for a dental emergency to be present,
                so it’s important to discuss your particular issue with a dental
                professional.
              </p>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default Emergencies;
