import React from "react";
import { InfoWindow } from "@react-google-maps/api";

class MapInfoWindow extends React.Component {
  render() {
    return (
      <div>
        <h4>St. Cloud Smiles</h4>
        <span>Dental Clinic</span>
      </div>
    );
  }
}

export default MapInfoWindow;
