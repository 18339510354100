import React from "react";
import {
  InfoWindow,
  Marker,
  GoogleMap,
  LoadScript,
} from "@react-google-maps/api";
import MapInfoWindow from "./MapInfoWindow";

class Map extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      windowWidth: window.innerWidth,
      windowHeight: window.innerHeight,
      info: false,
    };
  }

  handleResize = (e) => {
    this.setState({ windowWidth: window.innerWidth });
  };

  componentDidMount() {
    window.addEventListener("resize", this.handleResize);
  }

  componentWillUnmount() {
    window.addEventListener("resize", this.handleResize);
  }

  handleMouseOver = (e) => {
    this.setState({ info: true });
  };

  handleMouseExit = (e) => {
    this.setState({ info: false });
  };

  render() {
    const { windowWidth, windowHeight, info } = this.state;
    const containerStyle = {
      width: { windowWidth },
      height: "500px",
    };

    const center = {
      lat: 28.24336,
      lng: -81.25661,
    };

    return (
      <div className="p-5">
        <LoadScript googleMapsApiKey="AIzaSyA-fOgP_KXd503uVgHWNE59WYBrQPMlujo">
          <GoogleMap
            mapContainerStyle={containerStyle}
            center={center}
            Marker={center}
            zoom={16}
          >
            <Marker position={center} onMouseOver={this.handleMouseOver}>
              {info && (
                <InfoWindow onCloseClick={this.handleMouseExit}>
                  <div>
                    <h4>St. Cloud Smiles</h4>
                    <span>Dental Clinic</span>
                  </div>
                </InfoWindow>
              )}
            </Marker>
          </GoogleMap>
        </LoadScript>
      </div>
    );
  }
}

export default Map;
